const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://docs.trusted.help",
		"gaTrackingId": null
	},
	"header": {
		"logo": "http://trusted.help/TrustedHelpLogo.png",
		"logoLink": "/",
		"title": "Trusted.Help | Office 365 Training",
		"githubUrl": "",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
		"search": {
			"enabled": false,
			"indexName": "",
			"algoliaAppId": process.env.GATSBY_ALGOLIA_APP_ID,
			"algoliaSearchKey": process.env.GATSBY_ALGOLIA_SEARCH_KEY,
			"algoliaAdminKey": process.env.ALGOLIA_ADMIN_KEY
		}
	},
	"sidebar": {
		"forcedNavOrder": [
			"/introduction",
			"/teams"
		],
		"links": [
			{ "text": "Waterstons", "link": "https://www.waterstons.com"},
		],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "Office 365 Training | Trusted.Help",
		"description": "",
		"ogImage": null,
		"docsLocation": "https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content",
		"favicon": "http://trusted.help/TrustedHelpLogo.png"
	},
};

module.exports = config;